import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { transformData } from "helpers/dataTransformer"
import {
  getAdminConsignorService,
  getAdminConsignorInAddConsignorService,
  getAdminConsignorByIdService,
  createAdminConsignorService,
  updateAdminConsignorService,
  deleteAdminConsignorService,
  deleteAdminConsignorFieldService,
} from "services/admin-consignor"

export const getAdminConsignorList = createAsyncThunk(
  "getAdminConsignorList",
  async (params, { rejectWithValue }) => {
    return await getAdminConsignorService(params, rejectWithValue)
  }
)

export const getAdminConsignorListInAddConsignor = createAsyncThunk(
  "getAdminConsignorListInAddConsignor",
  async (params, { rejectWithValue }) => {
    return await getAdminConsignorInAddConsignorService(params, rejectWithValue)
  }
)

// export const getAdminConsignorById = createAsyncThunk(
//   "getAdminConsignorById",
//   async (params, { rejectWithValue }) => {
//     return await getAdminConsignorByIdService(params, rejectWithValue)
//   }
// )

export const createAdminConsignor = createAsyncThunk(
  "createAdminConsignor",
  async (payload, { rejectWithValue }) => {
    return await createAdminConsignorService(payload, rejectWithValue)
  }
)

export const updateAdminConsignor = createAsyncThunk(
  "updateAdminConsignor",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateAdminConsignorService(id, data, rejectWithValue, config)
  }
)

// export const deleteAdminConsignor = createAsyncThunk(
//   "/deleteAdminConsignor",
//   async (id, { rejectWithValue }) => {
//     return await deleteAdminConsignorService(id, rejectWithValue)
//   }
// )

// export const deleteAdminConsignorField = createAsyncThunk(
//   "/deleteAdminConsignorField",
//   async (id, { rejectWithValue }) => {
//     return await deleteAdminConsignorFieldService(id, rejectWithValue)
//   }
// )

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  adminConsignorListData: [],
  metaConsignorList: null,
  adminConsignorListInAddConsignorData: [],
  metaConsignorListInAddConsignor: null,
  adminConsignorByIdData: null,
  included: [],
  logs: initialLog,
  meta: null,
}

const slice = createSlice({
  name: "adminConsignor",
  initialState,
  reducers: {
    resetAdminConsignorLogs: state => {
      state.logs = initialLog
    },
    adminConsignorByIdData: state => {
      state.logs = initialLog
      state.adminConsignorByIdData = null
    },
  },
  extraReducers: {
    [getAdminConsignorList.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getAdminConsignorList",
      }
    },
    [getAdminConsignorList.fulfilled]: (state, action) => {
      console.log(
        "getAdminConsignorList API RESPONSE:",
        transformData(action.payload)
      )
      state.metaConsignorList = action.payload.result.meta
      state.adminConsignorListData = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getAdminConsignorList.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        loading: false,
        error: action?.payload?.errors,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getAdminConsignorListInAddConsignor.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getAdminConsignorListInAddConsignor",
      }
    },
    [getAdminConsignorListInAddConsignor.fulfilled]: (state, action) => {
      console.log(
        "getAdminConsignorListInAddConsignor API RESPONSE:",
        transformData(action.payload)
      )
      state.metaConsignorListInAddConsignor = action.payload.result.meta
      state.adminConsignorListInAddConsignorData = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getAdminConsignorListInAddConsignor.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        loading: false,
        error: action?.payload?.errors,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    // [getAdminConsignorById.pending]: state => {
    //   state.logs = {
    //     ...initialLog,
    //     loading: true,
    //     type: "getAdminConsignorById",
    //   }
    // },
    // [getAdminConsignorById.fulfilled]: (state, action) => {
    //   console.log(
    //     "getAdminConsignorById API RESPONSE:",
    //     transformData(action.payload)
    //   )
    //   state.adminConsignorByIdData = transformData(action.payload)
    //   state.logs = { ...state.logs, success: true, loading: false }
    // },
    // [getAdminConsignorById.rejected]: (state, action) => {
    //   state.logs = {
    //     ...state.logs,
    //     loading: false,
    //     error: action?.payload?.errors,
    //     message: action?.payload?.errors ? (
    //       <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
    //     ) : (
    //       "Something went wrong"
    //     ),
    //   }
    // },
    [createAdminConsignor.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "createAdminConsignor",
      }
    },
    [createAdminConsignor.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [createAdminConsignor.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateAdminConsignor.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateAdminConsignor",
      }
    },
    [updateAdminConsignor.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
      // console.log("PUT Notif Success")
    },
    [updateAdminConsignor.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
      // console.log("PUT Notif Error")
    },
    // [deleteAdminConsignor.pending]: state => {
    //   state.logs = {
    //     ...initialLog,
    //     loading: true,
    //     type: "deleteAdminConsignor",
    //   }
    // },
    // [deleteAdminConsignor.fulfilled]: (state, action) => {
    //   state.logs = {
    //     ...state.logs,
    //     success: true,
    //     loading: false,
    //   }
    // },
    // [deleteAdminConsignor.rejected]: (state, action) => {
    //   state.logs = {
    //     ...state.logs,
    //     error: true,
    //     loading: false,
    //     message: action?.payload?.errors
    //       ? action?.payload?.errors[0]?.detail
    //       : "Something went wrong",
    //   }
    // },
    // [deleteAdminConsignorField.pending]: state => {
    //   state.logs = {
    //     ...initialLog,
    //     loading: true,
    //     type: "deleteAdminConsignorField",
    //   }
    // },
    // [deleteAdminConsignorField.fulfilled]: (state, action) => {
    //   state.logs = {
    //     ...state.logs,
    //     success: true,
    //     loading: false,
    //   }
    // },
    // [deleteAdminConsignorField.rejected]: (state, action) => {
    //   state.logs = {
    //     ...state.logs,
    //     error: true,
    //     loading: false,
    //     message: action?.payload?.errors
    //       ? action?.payload?.errors[0]?.detail
    //       : "Something went wrong",
    //   }
    // },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export const resetAdminConsignorLogs = () => dispatch => {
  dispatch(slice.actions.resetAdminConsignorLogs())
}

export const adminConsignorByIdData = () => dispatch => {
  dispatch(slice.actions.adminConsignorByIdData())
}

export default slice
