import { get } from "helpers/api_helper"

const STATES_BASE_URL = "/states"

export const getAreaOfDeliveryService = (params, rejectWithValue) =>
  get(
    STATES_BASE_URL,
    {
      params,
    },
    rejectWithValue
  )