export const calculateDiscountedPrice = (originalPrice, discount) => {
  // console.log("ORIGINAL PRICE: ", originalPrice)
  // console.log("discount: ", discount)
  // Ensure both inputs are valid numbers
  if (isNaN(originalPrice) || isNaN(discount)) {
    return "API error. Invalid input. Please enter valid numbers."
  }

  // Ensure discount is between 0 and 100
  if (discount < 0 || discount > 100) {
    return "Invalid discount. Please enter a value between 0 and 100."
  }

  // Calculate the discounted price
  // const discountedPrice = originalPrice - (originalPrice * discount) / 100
  const discountedPrice =
    originalPrice - originalPrice * (discount / 100).toFixed(2)

  // Return the result
  return discountedPrice
}
