import { get, del, post, put } from "helpers/api_helper"

const ADMIN_GET_OTHERFEES_URL = "/other-fees"
const ADMIN_POST_OTHERFEES_URL = "/other-fees"
const ADMIN_PUT_OTHERFEES_URL = "/other-fees"
const ADMIN_DEL_OTHERFEES_FIELD_URL = "/other-fees-fields"

// ADMIN
export const getAdminOtherFeesService = (query, rejectWithValue) =>
  get(ADMIN_GET_OTHERFEES_URL, query, rejectWithValue)

export const getAdminOtherFeesByIdService = (id, rejectWithValue) =>
  get(`${ADMIN_GET_OTHERFEES_URL}/${id}?include[]=fields`, {}, rejectWithValue)

export const createAdminOtherFeesService = (data, rejectWithValue) =>
  post(ADMIN_POST_OTHERFEES_URL, data, {}, rejectWithValue)

export const updateAdminOtherFeesService = (
  otherFeeId,
  data,
  rejectWithValue,
  config = {}
) =>
  put(`${ADMIN_PUT_OTHERFEES_URL}/${otherFeeId}`, data, config, rejectWithValue)

export const deleteAdminOtherFeesService = (id, rejectWithValue) =>
  del(`${ADMIN_POST_OTHERFEES_URL}/${id}`, {}, rejectWithValue)

export const deleteAdminOtherFeesFieldService = (id, rejectWithValue) =>
  del(`${ADMIN_DEL_OTHERFEES_FIELD_URL}/${id}`, {}, rejectWithValue)
