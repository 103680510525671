import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider as ReduxProvider } from "react-redux"

// import store from "./store"
// import rtkStore from "./storeRtk"
import { persistor, store } from "./storeRtk"
import { PersistGate } from "redux-persist/integration/react"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </PersistGate>
  </ReduxProvider>
)
// root.render(
//      <ReduxProvider store={store}>
//     <React.Fragment>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </React.Fragment>
//      </ReduxProvider>
// )

serviceWorker.unregister()
