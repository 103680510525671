import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  // getCategoryService,
  createConfigService,
  updateConfigService,
  getConfigurationService,
  getConfigurationsService,
  getItemSpecificationService,
  getItemSpecificationsService,
  updateItemSpecificationService,
  createItemSpecificationService,
  deleteItemSpecificationService,
} from "services/item_specification.service"

import { transformData } from "helpers/dataTransformer"

export const getItemSpecifications = createAsyncThunk(
  "/getItemSpecifications",
  async (params, { rejectWithValue }) => {
    return await getItemSpecificationsService(params, rejectWithValue)
  }
)

export const getConfigurations = createAsyncThunk(
  "/getConfigurations",
  async (params, { rejectWithValue }) => {
    return await getConfigurationsService(params, rejectWithValue)
  }
)

export const getConfiguration = createAsyncThunk(
  "/getConfiguration",
  async (id, { rejectWithValue }) => {
    return await getConfigurationService(id, rejectWithValue)
  }
)

export const getItemSpecification = createAsyncThunk(
  "/getItemSpecification",
  async (id, { rejectWithValue }) => {
    return await getItemSpecificationService(id, rejectWithValue)
  }
)

export const additemSpecification = createAsyncThunk(
  "/additemSpecification",
  async (params, { rejectWithValue }) => {
    return await createItemSpecificationService(params, rejectWithValue)
  }
)

export const deleteItemSpecification = createAsyncThunk(
  "/deleteItemSpecification",
  async (id, { rejectWithValue }) => {
    return await deleteItemSpecificationService(id, rejectWithValue)
  }
)

export const updateItemSpecification = createAsyncThunk(
  "/updateItemSpecification",
  async ({ data, id }, { rejectWithValue }) => {
    return await updateItemSpecificationService(id, data, rejectWithValue)
  }
)

export const createConfig = createAsyncThunk(
  "/createConfig",
  async (params, { rejectWithValue }) => {
    return await createConfigService(params, rejectWithValue)
  }
)

export const updateConfig = createAsyncThunk(
  "/updateConfig",
  async ({ data, id }, { rejectWithValue }) => {
    return await updateConfigService(id, data, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  category: {},
  included: [],
  itemSpecifications: [],
  itemSpecification: {},
  logs: initialLog,
  configurations: [],
  configuration: {},
}

const slice = createSlice({
  name: "ItemSpecification",
  initialState,
  reducers: {
    resetData: state => {
      state = initialState
    },
    resetLoading: state => {
      state.logs = initialLog
    },
  },
  extraReducers: {
    [getConfiguration.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getConfiguration",
      }
    },
    [getConfiguration.fulfilled]: (state, action) => {
      state.configuration = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getConfiguration.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [updateConfig.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateConfig",
      }
    },
    [updateConfig.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateConfig.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [createConfig.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "createConfig",
      }
    },
    [createConfig.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [createConfig.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [getConfigurations.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getConfigurations",
      }
    },
    [getConfigurations.fulfilled]: (state, action) => {
      state.configurations = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getConfigurations.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [getItemSpecifications.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getItemSpecifications",
      }
    },
    [getItemSpecifications.fulfilled]: (state, action) => {
      state.itemSpecifications = transformData(action.payload)
      state.included = action.payload.result.included
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getItemSpecifications.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [getItemSpecification.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getItemSpecification",
      }
    },
    [getItemSpecification.fulfilled]: (state, action) => {
      state.itemSpecification = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getItemSpecification.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
      }
    },
    [additemSpecification.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "additemSpecification",
      }
    },
    [additemSpecification.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [additemSpecification.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [deleteItemSpecification.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "deleteItemSpecification",
      }
    },
    [deleteItemSpecification.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [deleteItemSpecification.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateItemSpecification.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateItemSpecification",
      }
    },
    [updateItemSpecification.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [updateItemSpecification.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const {
  actions: itemSpecificationActions,
  reducer: itemSpecificationReducer,
} = slice

export const { resetData, resetLoading } = itemSpecificationActions

export default slice
