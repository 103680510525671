import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { transformData } from "helpers/dataTransformer"
import { showProductReviewService } from "services/product.service"

export const showReview = createAsyncThunk(
  "/showReview",
  async (params, { rejectWithValue }) => {
    return await showProductReviewService(params, rejectWithValue)
  }
)

const initialState = {
  show: {
    loading: false,
    data: null,
    message: null,
    success: false,
  },
}

const slice = createSlice({
  name: "ProductReview",
  initialState,
  reducers: {},
  extraReducers: {
    [showReview.pending]: state => {
      state.show = {
        ...state.show,
        loading: true
      }
    },
    [showReview.fulfilled]: (state, action) => {
      const data = transformData(action.payload)
      
      state.show = {
        ...state.show,
        loading: false,
        success: true,
        data,
      }
    },
    [showReview.rejected]: (state) => {
      state.show = {
        ...state.show,
        loading: false,
        success: false,
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice
export default slice