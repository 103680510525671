import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { CREATE_PRODUCT, DELETE_PRODUCT, GET_PRODUCTS, GET_PRODUCTS_SPECIFICATIONS, UPDATE_PRODUCT } from "./actionTypes"
import { createProductSuccessful, createProductFailed } from "./actions"
import { createProductService, deleteProductService, getProductsService, updateProductService } from "services/merchant.service"

function* createProduct({ payload: product }) {
  try {
    const response = yield call(createProductService, product);
    yield put(createProductSuccessful(response))
   
  } catch (error) {
    yield put(createProductFailed(error))
  }
}

function* updateProduct({ payload: product }) {
  try {
    const response = yield call(updateProductService, product);
    yield put(updateProductSuccessful(response))
   
  } catch (error) {
    yield put(updateProductFailed(error))
  }
}

function* deleteProduct({ payload: product }) {
  try {
    const response = yield call(deleteProductService, product);
    yield put(deleteProductSuccessful(response))
   
  } catch (error) {
    yield put(deleteProductFailed(error))
  }
}

function* getProductsSpecifications({ payload: product }) {
  try {
    const response = yield call(deleteProductService, product);
    yield put(createProductSuccessful(response))
   
  } catch (error) {
    yield put(createProductFailed(error))
  }
}

export function* watchCreateProduct() {
  yield takeEvery(CREATE_PRODUCT, createProduct)
  yield takeEvery(UPDATE_PRODUCT, updateProduct)
  yield takeEvery(DELETE_PRODUCT, deleteProduct)
  yield takeEvery(GET_PRODUCTS_SPECIFICATIONS, getProductsSpecifications)
}

function* productsSaga() {
  yield all([fork(watchCreateProduct)])
}

export default productsSaga
