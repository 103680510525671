import { del, get, post, put } from "helpers/api_helper"

const PRODUCTS_BASE_URL = "/product-specifications"
const CONFIGURATION_BASE_URL = "/configurations"

export const getItemSpecificationsService = (params, rejectWithValue) =>
  get(
    PRODUCTS_BASE_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const getConfigurationsService = (params, rejectWithValue) =>
  get(
    CONFIGURATION_BASE_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const getItemSpecificationService = (id, rejectWithValue) =>
  get(`${PRODUCTS_BASE_URL}/${id}?include[]=option`, {}, rejectWithValue)

export const createItemSpecificationService = (data, rejectWithValue) =>
  post(`${PRODUCTS_BASE_URL}?include[]=option`, data, {}, rejectWithValue)

export const createConfigService = (data, rejectWithValue) =>
  post(`${CONFIGURATION_BASE_URL}`, data, {}, rejectWithValue)

// export const createCategoryService = (data, rejectWithValue) =>
//   post(PRODUCTS_BASE_URL, data, {}, rejectWithValue)

// export const getCategoryService = (id, rejectWithValue) =>
//   get(`${PRODUCTS_BASE_URL}/${id}`, {}, rejectWithValue)

export const updateItemSpecificationService = (id, data, rejectWithValue) =>
  post(`${PRODUCTS_BASE_URL}/${id}?include[]=option`, data, {}, rejectWithValue)

export const getConfigurationService = (id, rejectWithValue) =>
  get(
    `${CONFIGURATION_BASE_URL}/${id}?include[]=product_category_specifications`,
    {},
    rejectWithValue
  )

export const updateConfigService = (id, data, rejectWithValue) =>
  post(`${CONFIGURATION_BASE_URL}/${id}`, data, {}, rejectWithValue)

export const deleteItemSpecificationService = (id, rejectWithValue) =>
  del(`${PRODUCTS_BASE_URL}/${id}`, {}, rejectWithValue)
