export const transformData = inputData => {
  // Create a map to store included data by type and ID
  const includedDataMap = new Map();

  // Populate the included data map
  if (inputData.result.included) {
    inputData.result.included.forEach(includedItem => {
      const key = `${includedItem.type}_${includedItem.id}`;
      includedDataMap.set(key, includedItem);
    });
  }

  // Recursive function to process relationships and include related data in attributes
  function processItem(item) {
    const { attributes, relationships, id } = item || {};
    const combinedAttributes = { ...attributes, id };

    for (const relKey in relationships) {
      const relationship = relationships[relKey];

      if (relationship.data) {
        if (Array.isArray(relationship.data)) {
          combinedAttributes[relKey] = relationship.data.map(relatedItem => {
            const key = `${relatedItem.type}_${relatedItem.id}`;
            return processItem(includedDataMap.get(key));
          });
        } else {
          const key = `${relationship.data.type}_${relationship.data.id}`;
          combinedAttributes[relKey] = processItem(includedDataMap.get(key));
        }
      }
    }

    return combinedAttributes;
  }

  if (Array.isArray(inputData.result.data)) {
    return inputData.result.data.map(item => {
      return {
        type: item.type,
        id: item.id,
        links: item.links,
        attributes: processItem(item),
      };
    });
  } else {
    return [
      {
        type: inputData.result.data.type,
        id: inputData.result.data.id,
        links: inputData.result.data.links,
        attributes: processItem(inputData.result.data),
      },
    ];
  }
};
