export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const CREATE_PRODUCT_SUCCESSFUL = "CREATE_PRODUCT_SUCCESSFUL"
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED"

export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESSFUL = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAIL"

export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESSFUL = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAIL"

export const GET_PRODUCTS_SPECIFICATIONS = "GET_PRODUCTS_SPECIFICATIONS"
export const GET_PRODUCTS_SPECIFICATIONS_SUCCESSFUL = "GET_PRODUCTS_SPECIFICATIONS_SUCCESSFUL"
export const GET_PRODUCTS_SPECIFICATIONS_FAILED = "GET_PRODUCTS_SPECIFICATIONS_FAILED"

