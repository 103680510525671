import { get, del, post, put } from "helpers/api_helper"

const ADMIN_GET_CONSIGNOR_URL = "/consignor"
const ADMIN_GET_CONSIGNOR_INADDCONSIGNOR_URL = "/merchants"
const ADMIN_POST_CONSIGNOR_URL = "/consignor?[include]=consignor_documents"
const ADMIN_PUT_CONSIGNOR_URL = "/consignor"
const ADMIN_DEL_CONSIGNOR_FIELD_URL = "/consignor-fields"

// ADMIN
export const getAdminConsignorService = (query, rejectWithValue) =>
  get(ADMIN_GET_CONSIGNOR_URL, { params: query }, rejectWithValue)

export const getAdminConsignorInAddConsignorService = (
  query,
  rejectWithValue
) =>
  get(
    ADMIN_GET_CONSIGNOR_INADDCONSIGNOR_URL,
    { params: query },
    rejectWithValue
  )

export const getAdminConsignorByIdService = (id, rejectWithValue) =>
  get(`${ADMIN_GET_CONSIGNOR_URL}/${id}?include[]=fields`, {}, rejectWithValue)

export const createAdminConsignorService = (data, rejectWithValue) =>
  post(ADMIN_POST_CONSIGNOR_URL, data, {}, rejectWithValue)

export const updateAdminConsignorService = (
  adminConsignorId,
  data,
  rejectWithValue,
  config = {}
) =>
  post(
    `${ADMIN_PUT_CONSIGNOR_URL}/${adminConsignorId}?include[]=consignor_documents`,
    data,
    config,
    rejectWithValue
  )

export const deleteAdminConsignorService = (id, rejectWithValue) =>
  del(`${ADMIN_POST_CONSIGNOR_URL}/${id}`, {}, rejectWithValue)

export const deleteAdminConsignorFieldService = (id, rejectWithValue) =>
  del(`${ADMIN_DEL_CONSIGNOR_FIELD_URL}/${id}`, {}, rejectWithValue)
