import React from "react"
import { Outlet } from "react-router-dom"

// layouts Format
import VerticalLayout from "../components/VerticalLayout/"
import HorizontalLayout from "../components/HorizontalLayout/"

// REDUX
import { useSelector, useDispatch } from "storeRtk"

const Layout = props => {
  const { userData } = useSelector(state => state.authReduxSlice)
  const getLayout = role => {
    let Layout = role !== "Agency" ? VerticalLayout : HorizontalLayout
    return Layout
  }

  const role =
    userData?.role_type ??
    JSON.parse(localStorage.getItem("authUser"))?.role_type

  const Layout = getLayout(role)

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default Layout
