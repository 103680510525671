import { del, get, post, put } from "helpers/api_helper"

const MY_ORDERS_URL = "/orders"

export const getMyOrdersService = (params, rejectWithValue) =>
  get(
    MY_ORDERS_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const getMyOrdersByCustomerIdService = (
  agencyId,
  params = {},
  rejectWithValue
) =>
  get(
    `${MY_ORDERS_URL}?customer_id=${agencyId}&include[]=order_items.products.product_images.merchant_profile`,
    // `${MY_ORDERS_URL}?customer_id=${agencyId}&include[]=order_items.product.images`,
    {
      params: params,
    },
    rejectWithValue
  )

export const getOrdersByMerchantService = (id, params = {}, rejectWithValue) =>
  get(
    `${MY_ORDERS_URL}/by-merchant?include[]=order_items.products.merchant_profile&include[]=order_items.products.whole_sales
      &include[]=order_items.products.variations&include[]=order_items.products.variations.variation_options`,
    {
      params: params,
    },
    rejectWithValue
  )
