import { del, get, post, put } from "helpers/api_helper"

const PRODUCTS_BASE_URL = "/products"

export const getProductsService = (params, rejectWithValue) =>
  get(
    PRODUCTS_BASE_URL,
    {
      params: params,
    },
    rejectWithValue
  )
export const showProductService = (params, rejectWithValue) => get(`${PRODUCTS_BASE_URL}/${params}`, {}, rejectWithValue)
export const createProductService = (product, rejectWithValue) => post(PRODUCTS_BASE_URL, product, {}, rejectWithValue)
export const updateProductService = (payload, rejectWithValue) => post(`${PRODUCTS_BASE_URL}/${payload.id}`, payload.payload, {}, rejectWithValue)
export const deleteProductService = (id, rejectWithValue) => del(`${PRODUCTS_BASE_URL}/${id}`, {}, rejectWithValue)