import { del, get, post, put } from "helpers/api_helper"

const EWALLET = "/transactions"

export const getTransactionsService = (params, rejectWithValue) =>
  get(
    EWALLET,
    {
      params: params,
    },
    rejectWithValue
  )
