import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const initialState = {
  userDetails: null,
}

const slice = createSlice({
  name: "AuthUser",
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.userDetails = action.payload
    },
    logoutState(state, action) {
      state = initialState
    },
  },
  extraReducers: {
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { actions: authUserActions, reducer: authUserReducer } = slice

export const { setUserDetails, logoutState } = authUserActions

export default slice
