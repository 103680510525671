import { get, del, post, put } from "helpers/api_helper"

const AGENCY_GET_NOTIF_URL = "/notifications?include[]=user&per_page=9999"
const AGENCY_PUT_NOTIF_URL = "/notifications"

const MERCHANT_GET_NOTIF_URL = "/notifications?per_page=9999"
const MERCHANT_GET_NOTIF_URL_PAGE = "/notifications"
const MERCHANT_PUT_NOTIF_URL = "/notifications"

const ADMIN_GET_NOTIF_URL = "/notifications?per_page=9999"
const ADMIN_GET_NOTIF_URL_PAGE = "/notifications"
const ADMIN_PUT_NOTIF_URL = "/notifications"

// AGENCY
export const getNotificationService = query =>
  get(
    AGENCY_GET_NOTIF_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const updateNotificationService = (
  notificationId,
  data,
  rejectWithValue,
  config = {}
) =>
  put(
    `${AGENCY_PUT_NOTIF_URL}/${notificationId}`,
    data,
    config,
    rejectWithValue
  )

// MERCHANT
export const getMerchantNotificationService = (params, rejectWithValue) =>
  get(
    MERCHANT_GET_NOTIF_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const getMerchantNotificationPageService = (params, rejectWithValue) =>
  get(
    MERCHANT_GET_NOTIF_URL_PAGE,
    {
      params: params,
    },
    rejectWithValue
  )

export const updateMerchantNotificationService = (
  notificationId,
  data,
  rejectWithValue,
  config = {}
) =>
  put(
    `${MERCHANT_PUT_NOTIF_URL}/${notificationId}`,
    data,
    config,
    rejectWithValue
  )

// ADMIN
export const getAdminNotificationService = (query, rejectWithValue) =>
  get(ADMIN_GET_NOTIF_URL, query, rejectWithValue)

export const getAdminNotificationPageService = (params, rejectWithValue) =>
  get(
    ADMIN_GET_NOTIF_URL_PAGE,
    {
      params: params,
    },
    rejectWithValue
  )

export const updateAdminNotificationService = (
  notificationId,
  data,
  rejectWithValue,
  config = {}
) =>
  put(`${ADMIN_PUT_NOTIF_URL}/${notificationId}`, data, config, rejectWithValue)
