import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getAreaOfDeliveryService } from "services/emarketplace.service"

export const getAreaOfDelivery = createAsyncThunk("getAreaOfDelivery", async (params, { rejectWithValue }) => {
  return await getAreaOfDeliveryService(params, rejectWithValue)
})

const initialState = {
  fetch: {
    loading: false,
    data: [],
    success: false,
    error: false,
    message: null
  }
}

const slice = createSlice({
  name: "AreaOfDeliveries",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [getAreaOfDelivery.pending]: state => {
      state.fetch = {
        ...state.fetch,
        loading: true
      }
    },
    [getAreaOfDelivery.fulfilled]: (state, action) => {
      state.fetch = {
        ...state.fetch,
        data: action.payload.result.data,
        loading: false,
        error: true,
        success: true
      }
    },
    [getAreaOfDelivery.rejected]: (state, action) => {
      state.error = action.error.message
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export default slice