import { transformData } from "helpers/dataTransformer"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getTransactionsService } from 'services/ewallet_service';

export const getTransactions = createAsyncThunk(
  "/getTransactions",
  async ({ params = {} }, { rejectWithValue }) => {
    return await getTransactionsService(params, rejectWithValue, {})
  }
)

// export const getTransactions = createAsyncThunk(
//   "/getTransactions",
//   async ({ params = {} }, { rejectWithValue }) => {
//     return getTransactionsService(params, rejectWithValue, {});
//   }
// );

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  transactions: [],
  logs: initialLog,
}

const slice = createSlice({
  name: "eWallet",
  initialState,
  reducers: {},
  extraReducers: {
    [getTransactions.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getTransactions",
      }
    },
    [getTransactions.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }

      state.transactions = transformData(action.payload)
    },
    [getTransactions.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { actions: ewalletActions, reducer: ewalletReducer } = slice

// export const { resetData } = ewalletActions

export default slice
