import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESSFUL,
  CREATE_PRODUCT_FAILED,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESSFUL,
  UPDATE_PRODUCT_FAILED,
  GET_PRODUCTS_SPECIFICATIONS,
  GET_PRODUCTS_SPECIFICATIONS_SUCCESSFUL,
  GET_PRODUCTS_SPECIFICATIONS_FAILED,
} from "./actionTypes"

export const createProduct = product => {
  return {
    type: CREATE_PRODUCT,
    payload: { product }
  }
}

export const createProductSuccessful = product => {
  return {
    type: CREATE_PRODUCT_SUCCESSFUL,
    payload: product,
  }
}

export const createProductFailed = product => {
  return {
    type: CREATE_PRODUCT_FAILED,
    payload: product,
  }
}

export const updateProduct = product => {
  return {
    type: UPDATE_PRODUCT,
    payload: { product }
  }
}

export const updateProductSuccessful = product => {
  return {
    type: UPDATE_PRODUCT_SUCCESSFUL,
    payload: product,
  }
}

export const updateProductFailed = product => {
  return {
    type: UPDATE_PRODUCT_FAILED,
    payload: product,
  }
}

export const getProductsSpecifications = categories => {
  return {
    type: GET_PRODUCTS_SPECIFICATIONS,
    payload: categories
  }
}

export const getProductsSpecificationsSuccessful = categories => {
  return {
    type: GET_PRODUCTS_SPECIFICATIONS_SUCCESSFUL,
    payload: categories
  }
}

export const getProductsSpecificationsFailed = categories => {
  return {
    type: GET_PRODUCTS_SPECIFICATIONS_FAILED,
    payload: categories
  }
}

