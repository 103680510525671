// ORDERS
// CHECKOUT
// MY CART
// BUY NOW
// NOTE DO NOT USE FOR NOW
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const addBuyNowProduct = item => dispatch => {
  dispatch(slice.actions.addBuyNowProduct(item))
}

export const removeBuyNowProduct = () => dispatch => {
  dispatch(slice.actions.removeBuyNowProduct(null))
}

const initialState = {
  buyNowProduct: {
    data: null,
    isBuyNow: false
  },
  cart: {
    data: [],
    loading: false,
    success: false,
    error: false,
    message: null
  }
}

const slice = createSlice({
  name: "EMarketplace",
  initialState,
  reducers: {
    addBuyNowProduct(state, action) {
      // state.buyNowProduct = {
      //   ...state.buyNowProduct,
      //   isBuyNow: true,
      //   data: action.payload,
      // }
    },
    removeBuyNowProduct(state, action) {
      // state.buyNowProduct = {
      //   ...state.buyNowProduct,
      //   isBuyNow: true,
      //   data: action.payload,
      // }
    },
  },
  extraReducers: {
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export default slice