import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { transformData } from "helpers/dataTransformer"
import {
  getAdminOtherFeesService,
  getAdminOtherFeesByIdService,
  createAdminOtherFeesService,
  updateAdminOtherFeesService,
  deleteAdminOtherFeesService,
  deleteAdminOtherFeesFieldService,
} from "services/admin-other-fees.service"

export const getAdminOtherFees = createAsyncThunk(
  "getAdminOtherFees",
  async (params, { rejectWithValue }) => {
    return await getAdminOtherFeesService(params, rejectWithValue)
  }
)

export const getAdminOtherFeesById = createAsyncThunk(
  "getAdminOtherFeesById",
  async (params, { rejectWithValue }) => {
    return await getAdminOtherFeesByIdService(params, rejectWithValue)
  }
)

export const createAdminOtherFees = createAsyncThunk(
  "/createAdminOtherFees",
  async (payload, { rejectWithValue }) => {
    return await createAdminOtherFeesService(payload, rejectWithValue)
  }
)

export const updateAdminOtherFees = createAsyncThunk(
  "updateAdminOtherFees",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateAdminOtherFeesService(id, data, rejectWithValue, config)
  }
)

export const deleteAdminOtherFees = createAsyncThunk(
  "/deleteAdminOtherFees",
  async (id, { rejectWithValue }) => {
    return await deleteAdminOtherFeesService(id, rejectWithValue)
  }
)

export const deleteAdminOtherFeesField = createAsyncThunk(
  "/deleteAdminOtherFeesField",
  async (id, { rejectWithValue }) => {
    return await deleteAdminOtherFeesFieldService(id, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  adminOtherFeesData: [],
  adminOtherFeesByIdData: null,
  included: [],
  logs: initialLog,
  meta: null,
}

const slice = createSlice({
  name: "adminOtherFees",
  initialState,
  reducers: {
    resetAdminOtherFeesLogs: state => {
      state.logs = initialLog
    },
    adminOtherFeesByIdData: state => {
      state.logs = initialLog
      state.adminOtherFeesByIdData = null
    },
  },
  extraReducers: {
    [getAdminOtherFees.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getAdminOtherFees",
      }
    },
    [getAdminOtherFees.fulfilled]: (state, action) => {
      console.log(
        "getAdminOtherFees API RESPONSE:",
        transformData(action.payload)
      )
      state.adminOtherFeesData = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getAdminOtherFees.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        loading: false,
        error: action?.payload?.errors,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getAdminOtherFeesById.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getAdminOtherFeesById",
      }
    },
    [getAdminOtherFeesById.fulfilled]: (state, action) => {
      console.log(
        "getAdminOtherFeesById API RESPONSE:",
        transformData(action.payload)
      )
      state.adminOtherFeesByIdData = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getAdminOtherFeesById.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        loading: false,
        error: action?.payload?.errors,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [createAdminOtherFees.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "createAdminOtherFees",
      }
    },
    [createAdminOtherFees.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [createAdminOtherFees.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [updateAdminOtherFees.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "updateAdminOtherFees",
      }
    },
    [updateAdminOtherFees.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
      // console.log("PUT Notif Success")
    },
    [updateAdminOtherFees.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
      // console.log("PUT Notif Error")
    },
    [deleteAdminOtherFees.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "deleteAdminOtherFees",
      }
    },
    [deleteAdminOtherFees.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [deleteAdminOtherFees.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    [deleteAdminOtherFeesField.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "deleteAdminOtherFeesField",
      }
    },
    [deleteAdminOtherFeesField.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
    },
    [deleteAdminOtherFeesField.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export const resetAdminOtherFeesLogs = () => dispatch => {
  dispatch(slice.actions.resetAdminOtherFeesLogs())
}

export const adminOtherFeesByIdData = () => dispatch => {
  dispatch(slice.actions.adminOtherFeesByIdData())
}

export default slice
