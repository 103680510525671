import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { transformData } from "helpers/dataTransformer"
import {
  getNotificationService,
  getAgencyNotificationsPageService,
} from "services/agency.service"
import { updateNotificationService } from "services/notifications.service"

export const getNotifications = createAsyncThunk(
  "getNotifications",
  async (params, { rejectWithValue }) => {
    return await getNotificationService(params, rejectWithValue)
  }
)

export const updateNotifications = createAsyncThunk(
  "putNotifications",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateNotificationService(id, data, rejectWithValue, config)
  }
)

export const getAgencyNotificationsPage = createAsyncThunk(
  "getAgencyNotificationsPage",
  async (params, { rejectWithValue }) => {
    return await getAgencyNotificationsPageService(params, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  agencyNotificationsArr: [],
  agencyNotificationsPageArr: [],
  included: [],
  logs: initialLog,
  meta: null,
}

const slice = createSlice({
  name: "agencyNotifications",
  initialState,
  reducers: {
    // resetAgencyMyOrdersState: state => {
    //   ;(state.myOrders = []), (state.included = []), (state.logs = initialLog)
    // },
  },
  extraReducers: {
    [getNotifications.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getNotifications",
      }
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.agencyNotificationsArr = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getNotifications.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        loading: false,
        error: action?.payload?.errors,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getAgencyNotificationsPage.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getAgencyNotificationsPage",
      }
    },
    [getAgencyNotificationsPage.fulfilled]: (state, action) => {
      state.meta = action.payload.result.meta
      state.agencyNotificationsPageArr = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getAgencyNotificationsPage.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [updateNotifications.pending]: state => {
      state.logs = { ...initialLog, loading: true, type: "updateNotifications" }
    },
    [updateNotifications.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
      // console.log("PUT Notif Success")
    },
    [updateNotifications.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
      // console.log("PUT Notif Error")
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

// export const resetAgencyMyOrdersStateThunk = () => dispatch => {
//   dispatch(slice.actions.resetAgencyMyOrdersState())
// }

export default slice
