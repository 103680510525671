import React, { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useSelector, useDispatch } from "storeRtk"

const Authmiddleware = props => {
  const { token, userData, logs } = useSelector(state => state.authReduxSlice)
  // const token = localStorage.getItem("jwt_token")
  const role = JSON.parse(localStorage.getItem("authUser"))?.role_type

  useEffect(() => {
    // Maintain authUser Object to avoid weird behavior when visiting page without token
    // console.log("AuthMiddleware: ", userData, logs)
    if (userData) {
      localStorage.setItem("authUser", JSON.stringify(userData))
    }
  }, [userData])

  // console.log("Triggered ROLE: ", props.role, role)
  if (token) {
    if (userData?.role_type === props.role) {
      return <React.Fragment>{props.children}</React.Fragment>
    } else {
      // Forbidden
      // console.log("Triggered Forbidden")
      return <Navigate to="/403" />
    }
  } else {
    // Authenticate or initialize useEffect in Ctx to ignore log-in UI
    // <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    // console.log("TRIGGERED REDIRECT due to NO TOKEN detected")
    return <Navigate to="/authenticate" />
    // window.location.href = process.env.REACT_APP_PHILGEPS_DEV_URL
  }
}

export default Authmiddleware
