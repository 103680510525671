import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { transformData } from "helpers/dataTransformer"
// import { getNotificationService } from "services/merchant-orders.service"
import {
  updateNotificationService,
  getMerchantNotificationService,
  getMerchantNotificationPageService,
} from "services/notifications.service"

export const getMerchantNotifications = createAsyncThunk(
  "getMerchantNotifications",
  async (params, { rejectWithValue }) => {
    return await getMerchantNotificationService(params, rejectWithValue)
  }
)

export const getMerchantNotificationsPage = createAsyncThunk(
  "getMerchantNotificationsPage",
  async (params, { rejectWithValue }) => {
    return await getMerchantNotificationPageService(params, rejectWithValue)
  }
)

export const updateMerchantNotifications = createAsyncThunk(
  "putMerchantNotifications",
  async ({ data, id, config = {} }, { rejectWithValue }) => {
    return await updateNotificationService(id, data, rejectWithValue, config)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  merchantNotificationsArr: [],
  merchantNotificationsPageArr: [],
  included: [],
  logs: initialLog,
  meta: null,
}

const slice = createSlice({
  name: "merchantNotifications",
  initialState,
  reducers: {
    // resetAgencyMyOrdersState: state => {
    //   ;(state.myOrders = []), (state.included = []), (state.logs = initialLog)
    // },
  },
  extraReducers: {
    [getMerchantNotifications.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getMerchantNotifications",
      }
    },
    [getMerchantNotifications.fulfilled]: (state, action) => {
      state.merchantNotificationsArr = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getMerchantNotifications.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getMerchantNotificationsPage.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getMerchantNotificationsPage",
      }
    },
    [getMerchantNotificationsPage.fulfilled]: (state, action) => {
      state.meta = action.payload.result.meta
      state.merchantNotificationsPageArr = transformData(action.payload)
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getMerchantNotificationsPage.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [updateMerchantNotifications.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "putMerchantNotifications",
      }
    },
    [updateMerchantNotifications.fulfilled]: (state, action) => {
      state.logs = {
        ...state.logs,
        success: true,
        loading: false,
      }
      // console.log("PUT Notif Success")
    },
    [updateMerchantNotifications.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors
          ? action?.payload?.errors[0]?.detail
          : "Something went wrong",
      }
      // console.log("PUT Notif Error")
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

// export const resetAgencyMyOrdersStateThunk = () => dispatch => {
//   dispatch(slice.actions.resetAgencyMyOrdersState())
// }

export default slice
