import { del, get, post, put } from "helpers/api_helper"

const ACTIVE_ORDERS_GET_URL =
  // "/order/list-merchant-orders?include[]=order_items_per_merchant&include[]=agency_profile&include[]=order_delivery_address" // previous API URL c/o Michel
  "/order/list-merchant-orders?include[]=order_items_per_merchant&include[]=agency_profile&include[]=order_items.order_delivery_address&include[]=order_items.products.whole_sales&include[]=order_items.products.variations&include[]=order_items.products.variations.variation_options" // New URL 10/25 c/o Michel
// "/order/list-merchant-orders?include[]=order_items_per_merchant&include[]=agency_profile&include[]=order_items.order_delivery_address" // dahil per order item na daw delivery option as per Michel
const ACTIVE_ORDERS_PUT_URL = "/order/update-status"

const ORDER_HISTORY_GET_URL =
  // "/order/list-merchant-orders?order_item_status_include[]=received&order_item_status_include[]=delivered"
  // "/order/list-merchant-orders?include[]=order_items_per_merchant&include[]=agency_profile&include[]=order_items.order_delivery_address"
  // "/order/list-merchant-orders?include[]=order_items_per_merchant.order_item_status_remarks&include[]=agency_profile&include[]=order_items.order_delivery_address"
  // "/order/list-merchant-orders?include[]=agency_profile&include[]=order_items.order_delivery_address"
  // "/order/list-merchant-orders?include[]=order_items_per_merchant.order_item_status_remarks&per_page=9999&status=approved"
  "/order/list-merchant-orders?include[]=order_items_per_merchant.order_item_status_remarks&include[]=agency_profile&include[]=order_items_per_merchant.order_delivery_address&status=approved&include[]=order_items_per_merchant.products.merchant_profile&include[]=order_documents"

const ACTIVE_ORDER_ITEM_POST_URL =
  "/order-item/update/status?include[]=order_items"
const ACTIVE_VIEW_ORDER_ITEM_GET_URL = "/order/show"

export const getActiveOrdersService = (params, rejectWithValue) =>
  get(
    ACTIVE_ORDERS_GET_URL,
    {
      params: params,
    },
    rejectWithValue
  )

export const getOrderHistoryService = (params, rejectWithValue) =>
  get(
    ORDER_HISTORY_GET_URL,
    {
      params: params,
    },
    rejectWithValue
  )

// /{{order_id}}?include[0]=order_items.products
export const getActiveOrdersByOrderIdService = (orderId, rejectWithValue) =>
  get(
    // `${ACTIVE_VIEW_ORDER_ITEM_GET_URL}/${orderId}?include[0]=order_items.products`,
    `${ACTIVE_VIEW_ORDER_ITEM_GET_URL}/${orderId}?include[]=order_items_per_merchant.order_item_status_remarks&include[]=order_documents`,

    {},
    rejectWithValue
  )

// export const createActiveOrdersService = (data, rejectWithValue) =>
//   post(ORDERS_GET_URL, data, {}, rejectWithValue)

export const updateActiveOrderService = (
  orderId,
  data,
  rejectWithValue,
  config = {}
) => post(`${ACTIVE_ORDERS_PUT_URL}`, data, config, rejectWithValue)

export const updateActiveOrderItemService = (
  orderId,
  data,
  rejectWithValue,
  config = {}
) => post(`${ACTIVE_ORDER_ITEM_POST_URL}`, data, config, rejectWithValue)

export const updateActiveOrderItemServiceFormData = (
  data,
  rejectWithValue,
  config = {}
) => post(`${ACTIVE_ORDER_ITEM_POST_URL}`, data, config, rejectWithValue)

// export const deleteActiveOrderService = (id, rejectWithValue) =>
//   del(`${ORDERS_GET_URL}/${id}`, {}, rejectWithValue)
