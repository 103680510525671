import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  // eslint-disable-next-line
  TypedUseSelectorHook,
} from "react-redux"
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./rootReducer"

//REDUX PERSIST
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"

// MIDDLE WARES
import thunk from "redux-thunk"
import createSagaMiddleware from "redux-saga"

import rootSaga from "../store/sagas"

// ----------------------------
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // merges [not replaced] incoming state 👉 initialState
  // blacklist: ["agencyMyOrdersSlice"], // DONT PERSIST specificReducer (array of strings)
  // whitelist: ['reducerName'] // PERSIST specificReducer (array of strings)
}
const persistedRootReducer = persistReducer(persistConfig, rootReducer)
// ----------------------------
const sagaMiddleware = createSagaMiddleware()
// ----------------------------

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: [sagaMiddleware, thunk],
})
sagaMiddleware.run(rootSaga)

// ----------------------------
// Type for useSelector state autocompletion without TypeScript
/**@type TypedUseSelectorHook<ReturnType<typeof store.getState>> */
export const useSelector = useReduxSelector

/**@type typeof store.dispatch */
export const useDispatch = () => useReduxDispatch()

// export default store
export const persistor = persistStore(store)
