import React from "react"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
  getMyOrdersService,
  getOrdersByMerchantService,
  getMyOrdersByCustomerIdService,
} from "services/agency-my-orders.service"
import { v4 as uuidv4 } from "uuid"
import { transformData } from "helpers/dataTransformer"

export const getMyOrders = createAsyncThunk(
  "/getMyOrders",
  async (params, { rejectWithValue }) => {
    return await getMyOrdersService(params, rejectWithValue)
  }
)

export const getMyOrdersByCustomerId = createAsyncThunk(
  "/getMyOrdersByCustomerId",
  async ({ id, params = {} }, { rejectWithValue }) => {
    return await getMyOrdersByCustomerIdService(id, params, rejectWithValue)
  }
)

export const getOrdersByMerchant = createAsyncThunk(
  "/getOrdersByMerchant",
  async ({ id, params = {} }, { rejectWithValue }) => {
    return await getOrdersByMerchantService(id, params, rejectWithValue)
  }
)

const initialLog = {
  type: "",
  error: false,
  message: null,
  loading: false,
  success: false,
}

const initialState = {
  myOrders: [],
  included: [],
  meta: [],
  pagination: {},
  logs: initialLog,
}

const slice = createSlice({
  name: "agencyMyOrders",
  initialState,
  reducers: {
    resetAgencyMyOrdersState: state => {
      ;(state.myOrders = []), (state.included = []), (state.logs = initialLog)
    },
  },
  extraReducers: {
    [getMyOrdersByCustomerId.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getMyOrdersByCustomerId",
      }
    },
    [getMyOrdersByCustomerId.fulfilled]: (state, action) => {
      state.myOrders = transformData(action.payload)
      // .filter(
      //   item => item.attributes.order_items[0]?.status !== "received"
      // )
      state.logs = { ...state.logs, success: true, loading: false }
      // // state.myOrders = action.payload.result.data
      // // state.included = action.payload.result?.included

      // // EXTRACT
      // const orderItemsArr = action?.payload?.result?.included?.filter(
      //   orderItem => orderItem?.type === "order_items"
      // )
      // const availableProductsArr = action?.payload?.result?.included?.filter(
      //   orderItem => orderItem?.type === "products"
      // )
      // const availableProductsImagesArr =
      //   action?.payload?.result?.included?.filter(
      //     orderItem => orderItem?.type === "product_images"
      //   )

      // // MUTATE
      // const parentOrdersArr = action?.payload?.result?.data?.map(order => {
      //   let orderId = order?.id
      //   let orderStatus = order?.attributes?.status
      //   let orderDate = order?.attributes?.created_at

      //   return {
      //     orderItems: order?.relationships?.order_items?.data?.map(
      //       orderItem => ({
      //         orderId: orderId,
      //         orderItemId: orderItem?.id,
      //         orderStatus: orderStatus,
      //         orderDate: orderDate,
      //       })
      //     ),
      //   }
      // })

      // let mergeOrderItemsWithStatusArr = []
      // for (const item of parentOrdersArr) {
      //   mergeOrderItemsWithStatusArr.push(...item?.orderItems)
      // }

      // // COMBINE
      // let tableData = []
      // tableData = orderItemsArr?.map(orderItem => {
      //   let productMatch = availableProductsArr?.find(
      //     product => Number(product?.id) === orderItem?.attributes?.product_id
      //   )

      //   let productImgMatch = availableProductsImagesArr?.find(
      //     img =>
      //       img?.attributes?.product_id === orderItem?.attributes?.product_id
      //   )

      //   let parentOrdersMatch = mergeOrderItemsWithStatusArr?.find(
      //     item => item?.orderItemId === orderItem?.id
      //   )

      //   return {
      //     key: uuidv4(),
      //     orderId: parentOrdersMatch?.orderId,
      //     orderStatus: parentOrdersMatch?.orderStatus,
      //     orderDate: parentOrdersMatch?.orderDate,
      //     orderItemId: orderItem?.id,
      //     ...productMatch?.attributes,
      //     ...orderItem?.attributes,
      //     productImg: productImgMatch?.attributes?.image,
      //   }
      // })
      // state.myOrders = tableData?.length > 0 ? [...tableData] : []
      // state.logs = { ...state.logs, success: true, loading: false }
    },
    [getMyOrdersByCustomerId.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    [getOrdersByMerchant.pending]: state => {
      state.logs = {
        ...initialLog,
        loading: true,
        type: "getOrdersByMerchant",
      }
    },
    [getOrdersByMerchant.fulfilled]: (state, action) => {
      // console.log("action.payload", transformData(action.payload))

      state.myOrders = transformData(action.payload)
      state.meta = action.payload.result.meta
      state.pagination = action?.payload?.result?.meta?.pagination
      // .filter(
      //   item => item.attributes.order_items[0]?.status !== "received"
      // )
      state.logs = { ...state.logs, success: true, loading: false }
    },
    [getOrdersByMerchant.rejected]: (state, action) => {
      state.logs = {
        ...state.logs,
        error: true,
        loading: false,
        message: action?.payload?.errors ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{`${action?.payload?.errors[0]?.code}. ${action?.payload?.errors[0]?.detail}`}</p>
          </>
        ) : action?.payload === undefined ? (
          <>
            <p style={{ fontWeight: 600 }}>{"Loading Table Data Failed. "}</p>
            <p>{"404/500/503. Server Error or Resource not found due to API response undefined."}</p>
          </>
        ) : (
          "Something went wrong"
        ),
      }
    },
    ["PURGE"]: () => {
      return initialState
    },
  },
})

export const { reducer } = slice

export const resetAgencyMyOrdersStateThunk = () => dispatch => {
  dispatch(slice.actions.resetAgencyMyOrdersState())
}

export default slice
