import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSampleState: false,
};

const slice = createSlice({
  name: "sampleReduxSlice",
  initialState,
  reducers: {
    sampleReducerFn(state) {
      state.isSampleState = !state.isSampleState;
    },
  },
});

export const { reducer } = slice;

export const sampleReducerFnThunkChange = () => (dispatch) => {
  dispatch(slice.actions.sampleReducerFn());
};

export default slice;

// HOW TO USE 👇

// import {
//   closeModal,
//   openModal,
// } from './slices/modals';
// import { useDispatch, useSelector } from './store';

// ACCESS STATE SLICE
// const {
//   isModalOpen,
// } = useSelector((state) => state.modals);

// TRIGGER DISPATCH / REDUCER FUNCTIONS
//const dispatch = useDispatch();
// dispatch(openModal());
