const searchTypes = {
  ALL: "all",
  CATEGORY: "category",
}

export default searchTypes

export const orderStatusENUMS = [
  "pending",
  "order_processing",
  "out_for_delivery",
  "delivered",
  "cancelled",
  "return_item",
  "approved",
]

export const productAvailable = {
  ALL: 'All',
  PS_AVAILABLE: "PS-Available",
}
