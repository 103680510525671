import { del, get, post, put } from "helpers/api_helper"

const AUTH_URL = "/getMgepsToken"

export const getMgepsTokenService = (params, rejectWithValue) =>
  get(
    AUTH_URL,
    {
      params: params,
    },
    rejectWithValue
  )
